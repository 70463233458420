import { FormEvent, useContext, useState, useRef } from 'react';
import AppContext from '../../../AppContext';
import './contact.scss';
import Container from '@mui/material/Container';
import Slide from '@mui/material/Slide';
import Fade from '../../fade/Fade';
import { Fab, styled } from '@mui/material';
import { TextField } from '@mui/material';
import emailjs from '@emailjs/browser';


const CustomTextField = styled(TextField)({
  '& label.Mui-focused': {
    color: 'black',
  },
  '& .MuiInput-underline:after': {
    borderBottomColor: 'black',
  },
  '& .MuiOutlinedInput-root': {
    '& fieldset': {
      borderColor: 'black',
    },
    '&:hover fieldset': {
      borderColor: 'black',
    },
    '&.Mui-focused fieldset': {
      borderColor: 'black',
    },
  },
});

function Contact() {

  const { toggleShowContactView, toggleShowThanksView } = useContext(AppContext);

  const [nameValue, setNameValue] = useState('');
  const [emailValue, setEmailValue] = useState('');
  const [companyValue, setCompanyValue] = useState('');
  const [messageValue, setMessageValue] = useState('');

  const form = useRef(null)
  
  const sendEmail = (e: FormEvent) => {
    e.preventDefault(); // prevents the page from reloading when you hit “Send”

    if (nameValue.length && emailValue.length && companyValue.length && messageValue.length) {

      emailjs.sendForm('service_1wm7d6p', 'template_au7mx12', form.current!, 'c6cjqm8n7_Xp-tJoe')
        .then(() => {
            // show the user a success message
            console.log('Mail successfully sent.');
            // Display the thanks view
            if (toggleShowContactView && toggleShowThanksView) {
              toggleShowContactView();
              toggleShowThanksView();
            }
        }, () => {
            // show the user an error
            console.log('Error sending mail.')
        });

    } else {
      console.log('Error, please fill out all forms.');
    }
  };
  

  const handleNameValueChange = (e: any) => {
    setNameValue(e.target.value);
  }

  const handleEmailValueChange = (e: any) => {
    setEmailValue(e.target.value);
  }

  const handleCompanyValueChange = (e: any) => {
    setCompanyValue(e.target.value);
  }

  const handleMessageValueChange = (e: any) => {
    setMessageValue(e.target.value);
  }

  return (
    <div>
      {/* Body */}
      <Container className="home-container snap-container">

        {/* Services - Vertical Snap */}
        <div className="project-snap-padding">
          <Fade>
            <Slide direction="up" in={true} mountOnEnter unmountOnExit timeout={{ enter: 1250, exit: 1250 }}>
              <div className="contact-container">
                <h1 className="contact-title">
                  How can we reach you?
                </h1>
                <form ref={form} onSubmit={sendEmail}>
                  <div className="contact-body">
                    <CustomTextField
                      id="name"
                      name="name" 
                      label="Name" 
                      variant="standard" 
                      margin="normal"
                      style={{ borderBottom: 'black 2px solid'}} 
                      required 
                      fullWidth 
                      inputProps={{style: {fontSize: 20}}}
                      InputLabelProps={{style: {fontSize: 20}}}
                      onChange={handleNameValueChange}
                      value={nameValue}
                    />
                    <CustomTextField 
                      id="email" 
                      name="email" 
                      label="Email" 
                      variant="standard" 
                      margin="normal"
                      style={{ borderBottom: 'black 2px solid'}} 
                      required 
                      fullWidth 
                      inputProps={{style: {fontSize: 20}}}
                      InputLabelProps={{style: {fontSize: 20}}}
                      onChange={handleEmailValueChange}
                      value={emailValue}
                    />
                    <CustomTextField 
                      id="company" 
                      name="company" 
                      label="Company" 
                      variant="standard" 
                      margin="normal"
                      style={{ borderBottom: 'black 2px solid'}} 
                      required 
                      fullWidth 
                      inputProps={{style: {fontSize: 20}}}
                      InputLabelProps={{style: {fontSize: 20}}}
                      onChange={handleCompanyValueChange}
                      value={companyValue}
                    />
                    <CustomTextField 
                      id="message" 
                      name="message"
                      label="Message" 
                      variant="standard" 
                      margin="normal"
                      style={{ borderBottom: 'black 2px solid'}} 
                      fullWidth 
                      multiline 
                      inputProps={{style: {fontSize: 20}}}
                      InputLabelProps={{style: {fontSize: 20}}}
                      onChange={handleMessageValueChange}
                      value={messageValue}
                    />
                  </div>

                  <div className="submit-button-wrapper">
                    <Fab 
                      type="submit"
                      style={{ transform: 'scale(1.4)' }} 
                      className="submit-button" 
                      color="inherit" 
                      variant="extended"
                      value="send"
                    >
                      Submit
                    </Fab>
                  </div>
                </form>

              </div>
            </Slide>
          </Fade>
        </div>

      </Container>
    </div>
  );
}

export default Contact;