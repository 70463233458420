import React, { useContext } from 'react';
import AppContext from '../../AppContext';
import './footer.scss';
import logoIcon from '../../assets/logo/logoIcon.svg'
import logoText from '../../assets/logo/logoText.svg'
import instagram from '../../assets/logo/instagram.svg'
import linkedin from '../../assets/logo/linkedin.svg'
import facebook from '../../assets/logo/facebook.svg'


// Define the Footer component
const Footer = () => {
  
  const { showProjectView, toggleShowProjectView,
    showServicesView, toggleShowServicesView,
    showContactView, toggleShowContactView,
    showThanksView, toggleShowThanksView,
  } = useContext(AppContext);

  const socialIconSelected = (social: string) => {
    if (social === 'facebook') {
      window.open('https://www.facebook.com/every7seven/', '_blank')?.focus();
    } else if (social === 'linkedin') {
      window.open('https://www.linkedin.com/company/open-digital/', '_blank')?.focus();
    } else if (social === 'instagram') {
      window.open('https://www.instagram.com/opendigital.xyz/', '_blank')?.focus();
    } else {
      console.log('error: social media selection error');
    }
  }

  const openProjectPage = () => {
    // If project view already open, start back at services
    if (showProjectView) {
      // if service view is closed, open it
      if (!showServicesView && toggleShowServicesView) {
        toggleShowServicesView();
      }
      if (showContactView && toggleShowContactView) {
        // if contact view is open, close it
        toggleShowContactView();
      }
      if (showThanksView && toggleShowThanksView) {
        // If thanks view is open, close it
        toggleShowThanksView();
      }
    } else {
      if (toggleShowProjectView && toggleShowServicesView) {
        if (!showServicesView) {
          toggleShowServicesView();
        }
        toggleShowProjectView();
      }
    }
  }

  return (
    <footer className="footer-style" style={{ background: 'lightgray' }}>
      {/* Image on the left side of the footer */}
      <div className="footer-logo-icon-wrapper footer-padding-left">
        <img className="footer-logo-icon" src={logoIcon} alt="Logo" />
      </div>
      
      {/* Text beneath the image */}
      <div className="footer-logo-text-wrapper footer-padding-left">
        <img className="footer-logo-text" src={logoText} alt="Logo" />
      </div>

      {/* Social media icons */}
      <div className="footer-social-icon-wrapper footer-padding-left">
        <img className="footer-social-icon" src={facebook} alt="Facebook" onClick={() => socialIconSelected('facebook')} />
        <img className="footer-social-icon" src={linkedin} alt="Linkedin" onClick={() => socialIconSelected('linkedin')} />
        <img className="footer-social-icon" src={instagram} alt="Instagram" onClick={() => socialIconSelected('instagram')} />
      </div>

      {/* Copyright info at the bottom of the footer */}
      <div className="footer-legal-wrapper footer-padding-left">
        <div className="footer-legal-item">
          <span className="footer-legal-link" onClick={() => openProjectPage()}>Contact Us</span>
        </div>
        <div className="footer-legal-item">
          <a className="footer-legal-link" target="_blank" href="https://www.freeprivacypolicy.com/live/8d36d224-0729-4ff7-9b08-a173fb83c527">Web Terms and Conditions</a>
        </div>
        <div className="footer-legal-item">
          <a className="footer-legal-link" target="_blank" href="https://www.freeprivacypolicy.com/live/256ca9d0-3f36-488f-8e38-7b2ecc18741f">Privacy Policy</a>
          
        </div>
        <div className="footer-legal-item">
          <div className="icons-license-class">
            React icon by <a target="_blank" href="https://icons8.com">Icons8</a>
          </div>
          <div className="">Copyright © 2022 Open Digital</div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;