import { useContext, useState } from 'react';
import AppContext from '../../AppContext';
import './navbar.scss';
import logoIcon from '../../assets/logo/logoIcon.svg'
import Menu from '../menu/Menu';
import MenuIcon from '@mui/icons-material/Menu';


// Define the Navbar component
const Navbar = () => {
  // Set up state to track whether the mobile menu is open or closed
  const [menuOpen, setMenuOpen] = useState(false);

  const { showProjectView, toggleShowProjectView,
  } = useContext(AppContext);

  const handleMenuClick = () => {
    setMenuOpen(!menuOpen);
    const projectElement = document.getElementsByClassName('project-container')[0] as HTMLElement;
    const homeElement = document.getElementsByClassName('home-container')[0] as HTMLElement;
    if (projectElement) {
      projectElement.style.position = 'fixed';
    }
    if (homeElement) {
      homeElement.style.position = 'fixed';
    }
  }
  
  const scrollToTop = () => {
    // if project view is open, we need to change back from fixed, close project view, then scroll 
    if (showProjectView && toggleShowProjectView) {
      const projectElement = document.getElementsByClassName('project-container')[0] as HTMLElement;
      const homeElement = document.getElementsByClassName('home-container')[0] as HTMLElement;
      if (projectElement) {
        projectElement.style.position = '';
      }
      if (homeElement) {
        homeElement.style.position = '';
      }
      toggleShowProjectView();
    }
    document.getElementById('welcome')!.scrollIntoView({
        behavior: 'smooth'
    })
}

  return (
    <nav className="navbar-container">
      {/* Image on the left side of the navbar */}
      <img className="navbar-logo cursor-on-hover" src={logoIcon} alt="Logo" onClick={() => scrollToTop()} />

      {/* Mobile hamburger menu */}
      <button
        className="navbar-menu"
        onClick={() => handleMenuClick()}
        aria-label="Toggle mobile menu"
      >
        <MenuIcon className="center-menu-icon" />
      </button>

      {/* Menu - only shown on mobile when the menu is open */}
      {menuOpen ? <Menu menuOpen={menuOpen} setMenuOpen={setMenuOpen} /> : null}


    </nav>
  );
};

export default Navbar;