import './App.scss';
import { AppProvider } from './AppContext';
import Main from './components/main/Main';

function App() {
  
  return (
    <AppProvider>
      <Main />
    </AppProvider>
  );
}

export default App;
