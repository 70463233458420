import { useContext } from 'react';
import './services.scss';
import AppContext from '../../../AppContext';
import Container from '@mui/material/Container';
import Slide from '@mui/material/Slide';
import Fade from '../../fade/Fade';
import { Fab } from '@mui/material';

function Services() {

  const { handleSetProjectService, toggleShowServicesView, toggleShowContactView } = useContext(AppContext);

  const setService = (service: string) => {
    console.log('set service');
    if (handleSetProjectService && toggleShowServicesView && toggleShowContactView) {
      handleSetProjectService(service);
      toggleShowServicesView();
      toggleShowContactView();
    }
  }

  return (
    <div>
      {/* Body */}
      <Container className="home-container snap-container">

        {/* Services - Vertical Snap */}
        <div className="vertical-snap-center project-snap-padding">
            <Fade>
                <Slide direction="up" in={true} mountOnEnter unmountOnExit timeout={{ enter: 1250, exit: 1250 }}>
                <div className="services-container">
                  <h1 className="services-title">
                    How can we help you?
                  </h1>
                  
                  {/* TODO Need to add condition here for non-mobile */}

                  <div style={{ padding: '0px 16px'}} className="services-body">
                    <div className="services-button-wrapper">

                      <div className="services-button-row">
                          <Fab 
                              className="service-button" 
                              color="inherit" 
                              variant="extended"
                              onClick={() => setService('design')}
                          >
                            Design
                          </Fab>
                          <Fab 
                              className="service-button" 
                              color="inherit" 
                              variant="extended"
                              onClick={() => setService('development')}
                          >
                            Development
                          </Fab>
                      </div>

                      <div className="services-button-row">
                          <Fab 
                              className="service-button" 
                              color="inherit" 
                              variant="extended"
                              onClick={() => setService('consulting')}
                          >
                            Consulting
                          </Fab>
                          <Fab 
                              className="service-button" 
                              color="inherit" 
                              variant="extended"
                              onClick={() => setService('hosting')}
                          >
                            Hosting
                          </Fab>
                      </div>

                      <div className="services-button-row">
                          <Fab 
                              className="service-button" 
                              color="inherit" 
                              variant="extended"
                              onClick={() => setService('career')}
                          >
                            Career
                          </Fab>
                          <Fab 
                              className="service-button" 
                              color="inherit" 
                              variant="extended"
                              onClick={() => setService('questions')}
                          >
                            Questions
                          </Fab>
                      </div>

                    </div>
                  </div>
                </div>
                </Slide>
            </Fade>
        </div>

      </Container>
    </div>
  );
}

export default Services;