import { useContext } from 'react';
import './menu.scss';
import AppContext from '../../AppContext';
import logoIconWhite from '../../assets/logo/logoIconWhite.svg'
import instagramWhite from '../../assets/logo/instagramWhite.svg'
import linkedinWhite from '../../assets/logo/linkedinWhite.svg'
import facebookWhite from '../../assets/logo/facebookWhite.svg'
import CloseIcon from '@mui/icons-material/Close';

type MenuProps = {
  menuOpen: boolean;
  setMenuOpen: React.Dispatch<React.SetStateAction<boolean>>;
};

// Define the Navbar component
const Menu = ({ menuOpen, setMenuOpen }: MenuProps) => {

  const { showProjectView, toggleShowProjectView,
     showServicesView, toggleShowServicesView,
     showContactView, toggleShowContactView,
     showThanksView, toggleShowThanksView,
   } = useContext(AppContext);

  const handleMenuClick = () => {
    setMenuOpen(!menuOpen);
    const projectElement = document.getElementsByClassName('project-container')[0] as HTMLElement;
    const homeElement = document.getElementsByClassName('home-container')[0] as HTMLElement;
    if (projectElement) {
      projectElement.style.position = '';
    }
    if (homeElement) {
      homeElement.style.position = '';
    }
  }

  const scrollToSection = (id: string) => {
    // Ensure project view is closed before scrolling 
    if (showProjectView && toggleShowProjectView) {
      toggleShowProjectView();
    }
    document.getElementById(id)!.scrollIntoView({
      behavior: 'smooth'
    })
    handleMenuClick();
  }

  const openProjectPage = () => {
    console.log('opening project page');
    // If project view already open, start back at services
    if (showProjectView) {
      // if service view is closed, open it
      if (!showServicesView && toggleShowServicesView) {
        toggleShowServicesView();
      }
      // if contact view is open, close it
      if (showContactView && toggleShowContactView) {
        toggleShowContactView();
      }
      // If thanks view is open, close it
      if (showThanksView && toggleShowThanksView) {
        toggleShowThanksView();
      }
      // Now close menu after project view is properly configured
      setMenuOpen(!menuOpen);
    } else {
      if (toggleShowProjectView && toggleShowServicesView) {
        // Close the menu
        setMenuOpen(!menuOpen);
        if (!showServicesView) {
          // Show the services view if it is not shown
          toggleShowServicesView();
        }
        if (showContactView && toggleShowContactView) {
          // Hide the contact view if it is open
          toggleShowContactView();
        }
        if (showThanksView && toggleShowThanksView) {
          // Hide the thanks view if it is open
          toggleShowThanksView();          
        }
        // Show the projects view
        toggleShowProjectView();
      }
    }
  }

  const socialIconSelected = (social: string) => {
    if (social === 'facebook') {
      window.open('https://www.facebook.com/every7seven/', '_blank')?.focus();
    } else if (social === 'linkedin') {
      window.open('https://www.linkedin.com/company/open-digital/', '_blank')?.focus();
    } else if (social === 'instagram') {
      window.open('https://www.instagram.com/opendigital.xyz/', '_blank')?.focus();
    } else {
      console.log('error: social media selection error');
    }
  }

  return (
    <div className="menu-overlay-slide-in">

      <div className="menu-navbar-container">
        {/* Image on the left side of the navbar */}
        <img className="navbar-logo cursor-on-hover" src={logoIconWhite} alt="Logo" onClick={() => scrollToSection('welcome')} />

        <button
          className="close-menu"
          onClick={() => handleMenuClick()}
          aria-label="Toggle mobile menu"
          >
          <CloseIcon className="center-close-icon"/>
        </button>
      </div>

      <div className="menu-options-container">
        <div className="menu-option">
          <span className="cursor-on-hover" onClick={() => scrollToSection('about')}>About</span>
        </div>
        <div className="menu-option">
          <span className="cursor-on-hover" onClick={() => scrollToSection('services')}>Services</span>
        </div>
        <div className="menu-option">
          <span className="cursor-on-hover" onClick={() => scrollToSection('Consulting')}>Consulting</span>
        </div>
        <div className="menu-option">
          <span className="cursor-on-hover" onClick={() => openProjectPage()}>Contact</span>
        </div>
      </div>

      <div className="menu-social-icon-wrapper">
        <img className="menu-social-icon cursor-on-hover" src={facebookWhite} alt="Facebook" onClick={() => socialIconSelected('facebook')} />
        <img className="menu-social-icon cursor-on-hover" src={linkedinWhite} alt="Linkedin" onClick={() => socialIconSelected('linkedin')} />
        <img className="menu-social-icon cursor-on-hover" src={instagramWhite} alt="Instagram" onClick={() => socialIconSelected('instagram')} />
      </div>
    </div>

  );
};

export default Menu;