import { useContext } from 'react';
import AppContext from '../../AppContext';
import './project.scss';
import Navbar from '../navbar/Navbar';
import Container from '@mui/material/Container';
import Contact from './contact/Contact';
import Services from './services/Services';
import Thanks from './thanks/Thanks';

function Project() {

    const { showServicesView, showContactView, showThanksView } = useContext(AppContext);

    return (
        <div className="project-container">
            {/* Navbar */}
            <Navbar />

            {/* Body */}
            <Container className="snap-container remove-padding remove-max-width">
                {showServicesView ? <Services /> : null}  
                {showContactView ? <Contact /> : null}  
                {showThanksView ? <Thanks /> : null}  
            </Container>

        </div>
    );
}

export default Project;