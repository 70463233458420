import { useState, useRef, useEffect } from "react";
import './fade.scss';

const Fade = ( props: any ) => {
  const [isVisible, setVisible] = useState(true);
  const domRef: any = useRef();
  useEffect(() => {
    const observer = new IntersectionObserver(entries => {
      entries.forEach(entry => setVisible(entry.isIntersecting));
    });
    const currentNode = domRef.current;
    observer.observe(domRef.current);
    return () => observer.unobserve(currentNode);
  }, []);
  return (
    <div
      className={`fade-in-section ${isVisible ? 'is-visible' : ''}`}
      ref={domRef}
    >
      {props.children}
    </div>
  );
}

export default Fade;
