import { useContext } from 'react';
import './thanks.scss';
import AppContext from '../../../AppContext';
import Container from '@mui/material/Container';
import Slide from '@mui/material/Slide';
import Fade from '../../fade/Fade';
import { Fab } from '@mui/material';

function Thanks() {

  const { toggleShowProjectView, toggleShowThanksView } = useContext(AppContext);

  const closeProjectView = () => {
    if (toggleShowProjectView && toggleShowThanksView) {
      toggleShowProjectView();
      toggleShowThanksView();
      const projectElement = document.getElementsByClassName('project-container')[0] as HTMLElement;
      const homeElement = document.getElementsByClassName('home-container')[0] as HTMLElement;
      if (projectElement) {
        projectElement.style.position = '';
      }
      if (homeElement) {
        homeElement.style.position = '';
      }
    }
  }

  return (
    <div>
      {/* Body */}
      <Container className="home-container snap-container">

        {/* Services - Vertical Snap */}
        <div className="vertical-snap-center project-snap-padding">
            <Fade>
                <Slide direction="up" in={true} mountOnEnter unmountOnExit timeout={{ enter: 1250, exit: 1250 }}>
                <div className="services-container">

                  <h1 className="thanks-title">
                    Thanks! We'll be in touch soon.
                  </h1>

                  <div className="thanks-button-wrapper">
                    <Fab 
                      style={{ transform: 'scale(1.4)' }} 
                      className="thanks-button" 
                      color="inherit" 
                      variant="extended"
                      onClick={() => closeProjectView()}
                    >
                      Home
                    </Fab>
                  </div>

                </div>
                </Slide>
            </Fade>
        </div>

      </Container>
    </div>
  );
}

export default Thanks;