/**
 * Trademarked under Polynova Ventures LLC (no. 3866084)
 * 
 */

import { useContext } from 'react';
import './main.scss';
import Navbar from '../navbar/Navbar';
import Footer from '../footer/Footer';
import Fade from '../fade/Fade';
import logoTextAligned from '../../assets/logo/logoTextAligned.svg'
// import stars from '../../assets/icons/stars.svg'
import Grid from '@mui/material/Grid'
import Project from '../project/Project';
import AppContext from '../../AppContext';
import Container from '@mui/material/Container';
import Fab from '@mui/material/Fab';
import Slide from '@mui/material/Slide';
import Carousel from '../carousel/Carousel';
import AboutPhoto from '../../assets/images/aboutphoto.jpg'
import ConsultingPhoto from '../../assets/images/consultingphoto.jpg'

function Main() {

    const { showProjectView, toggleShowProjectView,
        showServicesView, toggleShowServicesView,
        showContactView, toggleShowContactView,
        showThanksView, toggleShowThanksView,
    } = useContext(AppContext);

    const openProjectPage = () => {
        if (toggleShowProjectView) {
            if (!showServicesView && toggleShowServicesView) {
                // if service view is closed, open it
                toggleShowServicesView();
            }
            if (showContactView && toggleShowContactView) {
                // if contact view is open, close it
                toggleShowContactView();
            }
            if (showThanksView && toggleShowThanksView) {
                // If thanks view is open, close it
                toggleShowThanksView();
            }
            toggleShowProjectView();
        }
    }
  
    return (
        <div>
            {/* Navbar */}
            <Navbar />

            {/* Body */}
            <Container className="home-container snap-container">
                {/* Welcome - Vertical Snap */}
                <div id="welcome" className="vertical-snap-start snap-padding">
                    <Fade>
                        <Slide direction="up" in={true} mountOnEnter unmountOnExit timeout={{ enter: 1250, exit: 1250 }}>
                            <img className="home-text-logo" src={logoTextAligned} alt="Logo" />
                        </Slide>
                    </Fade>
                    <Fade>
                        <Slide direction="up" in={true} mountOnEnter unmountOnExit timeout={{ enter: 1250, exit: 1250 }}>
                        <p className="home-paragraph">
                            We're here to help you succeed in the digital world. Whether you're just starting out or looking to expand
                            your online presence, we have the expertise and resources to turn your ideas into reality.
                        </p>
                        </Slide>
                    </Fade>
                    <Fade>
                        <Slide direction="up" in={true} mountOnEnter unmountOnExit timeout={{ enter: 1250, exit: 1250 }}>
                        <div className="home-button-wrapper">
                            <Fab 
                                style={{ transform: 'scale(1.4)' }} 
                                className="home-button" 
                                color="inherit" 
                                variant="extended"
                                onClick={() => openProjectPage()}
                            >
                            Let's get started
                            </Fab>
                        </div>
                        </Slide>
                    </Fade>
                </div>

                {/* About - Vertical Snap */}
                <div id="about" className="vertical-snap-center snap-padding">
                    <Fade>
                        <Slide direction="up" in={true} mountOnEnter unmountOnExit timeout={{ enter: 1250, exit: 1250 }}>
                        <div className="about-title-wrapper">
                            <h1> About us </h1>
                            {/* <img className="floating about-us-section-image" src={stars} /> */}
                        </div>
                        </Slide>
                    </Fade>
                    <Fade>
                        <Slide direction="up" in={true} mountOnEnter unmountOnExit timeout={{ enter: 1250, exit: 1250 }}>
                            {/* <Grid container spacing={0}> */}
                                {/* <Grid item xs={6} md={8}> */}
                                    <p className="home-paragraph">
                                    We work on a project by project basis, spending time to learn about our client's business before
                                    we start building. While in progress, our team provides updates each step of the way, ensuring 
                                    our work meets your expectations. We don't just deliver, we make sure to set our client up for
                                    success by providing maintenance and support to keep everything running smoothly.
                                    </p>
                                {/* </Grid> */}
                                {/* <Grid item xs={6} md={4}> */}
                                    {/* <img alt="about" style={{ objectFit: 'cover', width: 400, height: 300, marginTop: 52, }} src={AboutPhoto} /> */}
                                {/* </Grid> */}
                            {/* </Grid> */}
                        </Slide>
                    </Fade>
                </div>

                {/* Services - Vertical Snap */}
                <div id="services" className="vertical-snap-center snap-padding">
                    <Fade>
                        <Slide direction="up" in={true} mountOnEnter unmountOnExit timeout={{ enter: 1250, exit: 1250 }}>
                            <div className="services-title-wrapper">
                                <h1> Services </h1>
                            </div>
                        </Slide>
                    </Fade>
                    <Fade>
                        <Slide direction="up" in={true} mountOnEnter unmountOnExit timeout={{ enter: 1250, exit: 1250 }}>
                           <div><Carousel /></div>
                        </Slide>
                    </Fade>
                    <Fade>
                        <Slide direction="up" in={true} mountOnEnter unmountOnExit timeout={{ enter: 1250, exit: 1250 }}>
                            <p className="home-paragraph">
                                We offer a range of services, including the design and development of custom websites, web-based 
                                applications, and mobile applications. Additionally, we offer resources and strategies for the
                                deployment and hosting of your technology.
                            </p>
                        </Slide>
                    </Fade>
                </div>

                {/* Consulting - Vertical Snap */}
                <div id="Consulting" className="vertical-snap-center snap-padding">
                    <Fade>
                        <Slide direction="up" in={true} mountOnEnter unmountOnExit timeout={{ enter: 1250, exit: 1250 }}>
                            <div className="consulting-title-wrapper">
                                <h1> Consulting </h1>
                            </div>
                        </Slide>
                    </Fade>
                    <Fade>
                        <Slide direction="up" in={true} mountOnEnter unmountOnExit timeout={{ enter: 1250, exit: 1250 }}>
                        {/* <Grid container spacing={0}> */}
                            {/* <Grid item xs={6} md={4}> */}
                                {/* <img alt="consulting" style={{ marginLeft: 172, objectFit: 'cover', width: 400, height: 300, marginTop: 52, }} src={ConsultingPhoto} /> */}
                            {/* </Grid> */}
                            {/* <Grid item xs={6} md={8}> */}
                                <p className="home-paragraph">
                                    Need something else? We offer consulting services to help you navigate the
                                    ever-evolving world of technology. Whether you need machine learning implemented into your 
                                    product, a data pipeline to help automate your workflow, or just need some guidance on how 
                                    to make the most of technology, we have the expertise to help you succeed.
                                </p>
                            {/* </Grid> */}
                        {/* </Grid> */}
                        </Slide>
                    </Fade>
                </div>
            
                {/* Footer */}
                <div className="vertical-snap-center snap-padding">
                    <Footer />
                </div>
            </Container>

            {/* Project - only shown when user clicks contact */}
            {showProjectView ? <Project /> : null}

        </div>
    );
}

export default Main;
