import React, { useState, FC } from 'react';

interface IAppContext {
  showProjectView: boolean;
  toggleShowProjectView?: () => void;
  showServicesView: boolean;
  toggleShowServicesView?: () => void;
  showContactView: boolean;
  toggleShowContactView?: () => void;
  showThanksView: boolean;
  toggleShowThanksView?: () => void;
  projectService: string;
  handleSetProjectService?: (service: string) => void;
  projectContact: { name: string, email: string, company: string, message: string };
  handleSetProjectContact?: (contact: { name: string, email: string, company: string, message: string }) => void;
}

interface Props {
  children: React.ReactNode;
}

const defaultState = {
  showProjectView: false, 
  showServicesView: true,
  showContactView: false,
  showThanksView: false,
  projectService: '',
  projectContact: { name: '', email: '', company: '', message: '' },
};
  
const AppContext = React.createContext<IAppContext>(defaultState);

export const AppProvider: FC<Props> = ({ children }) => {
  const [showProjectView, setShowProjectView] = useState(defaultState.showProjectView);

  const [showServicesView, setShowServicesView] = useState(defaultState.showServicesView);
  const [showContactView, setShowContactView] = useState(defaultState.showContactView);
  const [showThanksView, setShowThanksView] = useState(defaultState.showThanksView);

  const [projectService, setProjectService] = useState(defaultState.projectService);
  const [projectContact, setProjectContact] = useState(defaultState.projectContact);

  const toggleShowProjectView = () => {
    setShowProjectView(!showProjectView);
  };

  const toggleShowServicesView = () => {
    setShowServicesView(!showServicesView);
  };

  const toggleShowContactView = () => {
    setShowContactView(!showContactView);
  };

  const toggleShowThanksView = () => {
    setShowThanksView(!showThanksView);
  };

  const handleSetProjectService = (service: string) => {
    setProjectService(service);
  }

  const handleSetProjectContact = (contact: { name: string, email: string, company: string, message: string }) => {
    setProjectContact(contact);
  }


  return (
    <AppContext.Provider
      value={{
        showProjectView,
        toggleShowProjectView,
        showServicesView,
        toggleShowServicesView,
        showContactView,
        toggleShowContactView,
        showThanksView,
        toggleShowThanksView,
        projectService,
        handleSetProjectService,
        projectContact,
        handleSetProjectContact,
      }}
    >
      {children}
    </AppContext.Provider>
  );
};

export default AppContext;