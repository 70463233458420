import './carousel.scss';
import Amazon from '../../assets/icons/amazon.svg'
import Docker from '../../assets/icons/docker.svg'
import Swift from '../../assets/icons/swift.svg'
import Python from '../../assets/icons/python.svg'
import React from '../../assets/icons/react.svg'
import Android from '../../assets/icons/android.svg'

const Carousel = () => {

  // state will be help in the context or parent component to avoid rerender issues

  return (
    <div className="carousel-wrapper">
      <div className="first-icon-primary" style={{ transform: 'scale(1.1)' }}>
        <img alt="amazon" src={Amazon} />
      </div>
      <div className="second-icon-primary" style={{ transform: 'scale(1.1)' }}>
        <img alt="docker" src={Docker} />
      </div>
      <div className="third-icon-primary" style={{ transform: 'scale(1.1)' }}>
        <img alt="swift" src={Swift} />
      </div>
      <div className="fourth-icon-primary" style={{ transform: 'scale(1.1)' }}>
        <img alt="python" src={Python} />
      </div>
      <div className="fifth-icon-primary" style={{ transform: 'scale(1.1)' }}>
        <img alt="react" src={React} />
      </div>
      <div className="sixth-icon-primary" style={{ transform: 'scale(1.1)' }}>
        <img alt="android" src={Android} />
      </div>

      <div className="first-icon-secondary" style={{ transform: 'scale(1.1)' }}>
        <img alt="amazon" src={Amazon} />
      </div>
      <div className="second-icon-secondary" style={{ transform: 'scale(1.1)' }}>
        <img alt="docker" src={Docker} />
      </div>
      <div className="third-icon-secondary" style={{ transform: 'scale(1.1)' }}>
        <img alt="swift" src={Swift} />
      </div>
      <div className="fourth-icon-secondary" style={{ transform: 'scale(1.1)' }}>
        <img alt="python" src={Python} />
      </div>
      <div className="fifth-icon-secondary" style={{ transform: 'scale(1.1)' }}>
        <img alt="react" src={React} />
      </div>
      <div className="sixth-icon-secondary" style={{ transform: 'scale(1.1)' }}>
        <img alt="android" src={Android} />
      </div>
    </div>
  );
}

export default Carousel;
